import React from 'react';

function Home() {
  return (
    <div>
      <div className="py-5 text-center">
        <h2>Home</h2>
      </div>
    </div>
  );
}

export default Home;
